<template>
  <Dot />
</template>

<script>
// @ is an alias to /src
import Dot from "@/components/Dot.vue";

export default {
  name: "DotView",
  components: {
    Dot
  }
};
</script>
